import React, { useState } from "react"
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useStore } from "../Store"
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {ApiUrl} from '../AppConstants';


const useStyles = makeStyles((theme) => ({
    divider:{
        width:'25px'
    },

    formPosition: {
        textAlign: 'center'
      },
    txtFieldMargins:{
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(2)
          }
    },
    btnMargins: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2)
    }

}));

export const EditAddress = (props) => {
    const [address, setAddress] = useState({ FirstAddress: props.addressLine1, 
                                             StreetDetails: props.addressLine2, 
                                             City: props.city, Phone:props.phone, pincode: props.pinCode,
                                             NickName:props.addressName,
                                             AddressId:props.addressId,
                                             GST:props.gst,
                                             ContactPerson:props.contactPerson,
                                             IsDefault: props.IsDefault
                                         })
    const [errors, setErrors] = useState({})
    const { userStore } = useStore()
    const classes= useStyles();

    const handleAddressChange = (event) => {
        var { name, value } = event.target
        address[name] = value

    }

    const handleSubmit = (event) => {
        console.log(props)
        console.log(address, 'addressss')
        if(validate()){
console.log('validate passed')
        address.userId = userStore.user.UserId
        console.log('userid', address.userId)
        fetch(ApiUrl + '/profile/AddUpdateAdddress', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': window.localStorage.token
            },
            body: JSON.stringify(address)
        })
            .then(result => {
                if (result.status === 200) {
                    result.json().then(body => {
                        if (body.success !== true) {
                            console.log('request failed', address)
                        }else{      
                        
                        props.AddressUpdated()
                        }
                    })       
                }
            })
        }

    }

    const handleChange = (addressId, event) => {
        //setState({ ...state, [event.target.name]: event.target.checked });
            setAddress({...address, IsDefault:event.target.checked});
      };
    
    const validate = (event) => {
        const temp = []
        console.log(address,'in validate')
        console.log((/^$|(^.{15}$)/).test(address.GST), 'gst validate')
      
        temp.addressName = address.NickName?"":"Shop name is required"
        temp.addressLine1 = address.FirstAddress ? "" : "Address Line 1 is required"
        temp.city = address.City ? "" : "City is required"
        temp.pinCode = (/^[1-9][0-9]{5}$/).test(address.pincode)?"":"invalid pin"
        // /^$|([6-9][0-9]{9})/
        temp.Phone = (/^[6-9][0-9]{9}$/).test(address.Phone)?"":"invalid phone number"
        temp.gst = (/^$|(^.{15}$)/).test(address.GST)?"":"invalid GST number"
        // temp.pinCode = (address.pincode) ? "" : "pin code is required"

        // if (!temp.pinCode) temp.pinCode = (address.pincode).toString().length === 6 ? "" : "Pincode should be 6 digits long"
       
        setErrors({ ...temp })
        return Object.values(temp).every(param => param === "")
    }


    return (
        <div className={classes.formPosition}>
            <TextField
                margin="normal"
                required
                id="addressName"
                label={window.localStorage.IsB2b==="true" ? "Shop & (Area)" : "Area"}
                name="NickName"
                onChange={handleAddressChange}
                defaultValue={props.addressName}             
                error={!!errors.addressName}
                helperText={errors.addressName}
                className={classes.txtFieldMargins}
            />
            
            <span className={classes.divider}></span>
            <TextField
                margin="normal"
                required
                id="addressLine1"
                label="Address Line 1"
                name="FirstAddress"
                onChange={handleAddressChange}
                error={!!errors.addressLine1}
                helperText={errors.addressLine1}
                defaultValue={props.addressLine1}
                className={classes.txtFieldMargins}
            />
            <br />
            <TextField
                margin="normal"
                id="Address"
                label="Address Line 2"
                name="StreetDetails"
                onChange={handleAddressChange}
                defaultValue={props.addressLine2}
                className={classes.txtFieldMargins}

            />
            
            <TextField
                margin="normal"
                required
                id="City"
                label="City"
                name="City"
                onChange={handleAddressChange}
                error={!!errors.city}
                helperText={errors.city}
                defaultValue={props.city}
                className={classes.txtFieldMargins}

            />
            <br />
            <TextField
                margin="normal"
                required
                id="Pin"
                label="Pin code (6 digits)"
                name="pincode"
                onChange={handleAddressChange}
                error={!!errors.pinCode}
                helperText={errors.pinCode}
                defaultValue={props.pinCode}
                className={classes.txtFieldMargins}
            />
            {window.localStorage.IsB2b==="true" &&
            <TextField
                margin="normal"
                required
                id="gst"
                label="GSTIN (15 digits)"
                name="GST"
                onChange={handleAddressChange}
                defaultValue={props.gst}
                error={!!errors.gst}
                helperText={errors.gst}
                className={classes.txtFieldMargins}
            />}
            
            <TextField
                margin="normal"
                id="contactPerson"
                label="Contact Person"
                name="ContactPerson"
                onChange={handleAddressChange}
                defaultValue={props.contactPerson}
                className={classes.txtFieldMargins}
            />
            
            <TextField
                margin="normal"
                required
                id="Phone"
                label="Phone (10 digits)"
                name="Phone"
                onChange={handleAddressChange}        
                defaultValue={props.phone}
                error={!!errors.Phone}
                helperText={errors.Phone}
                className={classes.txtFieldMargins}
            />
           <br/>
            <Button className={classes.btnMargins} variant="contained" color="primary" onClick={handleSubmit}>Update</Button>
            <Tooltip className={classes.btnMargins} title="Set as Default"><FormControlLabel control={<Switch checked={address.IsDefault}  onChange={(e) => handleChange(address.AddressId, e)} name="checkedB" color="primary"/>}label="Default"/></Tooltip>
            <br />
            <br />
            <br />

        </div>
    )
}