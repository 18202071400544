// Import the functions you need from the SDKs you need
//import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

////simpfo-b2b-test
// const firebaseConfig = {
//   apiKey: "AIzaSyAE9EelM0HBS13Fyxgp9kYFP20hobO61Ko",
//   authDomain: "simpfo-b2b-test.firebaseapp.com",
//   projectId: "simpfo-b2b-test",
//   storageBucket: "simpfo-b2b-test.appspot.com",
//   messagingSenderId: "348102567988",
//   appId: "1:348102567988:web:018f97eb6fb790d5dc0261"
// };

// ////halliangadi.in
// const firebaseConfig = {
//   apiKey: "AIzaSyD6Ts9hG8TPFRR9N2gguB39TY7o6UZUEgY",
//   authDomain: "halliangadi-b2c.firebaseapp.com",
//   projectId: "halliangadi-b2c",
//   storageBucket: "halliangadi-b2c.appspot.com",
//   messagingSenderId: "583989356727",
//   appId: "1:583989356727:web:d93b3b571904e6919fc532",
//   measurementId: "G-QCH37YXSNP"
// };

////halliorganics
// const firebaseConfig = {
//   apiKey: "AIzaSyBin0GtKJHWeHRsY3U5O8wOkwe-JS2OPwo",
//   authDomain: "halliorganics-simpfo.firebaseapp.com",
//   projectId: "halliorganics-simpfo",
//   storageBucket: "halliorganics-simpfo.appspot.com",
//   messagingSenderId: "953433784998",
//   appId: "1:953433784998:web:7700b562b1be84ad3cea67"
// };

////store.nithinorganics.com
const firebaseConfig = {
  apiKey: "AIzaSyDtQa1Tg2Zx7gU9t4tkpjvukJi4n9TBwFw",
  authDomain: "b2c-nithinorganics.firebaseapp.com",
  projectId: "b2c-nithinorganics",
  storageBucket: "b2c-nithinorganics.appspot.com",
  messagingSenderId: "401983592554",
  appId: "1:401983592554:web:9b50649e531df753515213"
};

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
export default firebaseConfig;
