import axios from "../axios";

export const FetchUserDetails = () => {
  const url = "/auth/login";
  const headers = {
    "Content-Type": "application/json",
    'x-access-token': window.localStorage.token
  };
  const data = {
    mobilenumber: window.localStorage.phone,
    logintype: "social",
    device_token: ""
  };
  return axios({ method: "post", url, headers, data });
}