import React, { useState, useEffect } from "react"
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useStore } from "../Store";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid, Hidden, Avatar,
  Card, CardContent, Container, CardActions
} from "@material-ui/core";
import { EditAddress } from './EditAddress'
import { blue } from '@material-ui/core/colors';
import PhoneIphoneOutlinedIcon from '@material-ui/icons/PhoneIphoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import NoProfilePic from '../Images/no-profile-picture.jpg'
import { Spinner } from '../Spinner'
import Footer from '../Footer'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import { EditUserDetails } from './EditUserDetails'
import FlashMessage from 'react-flash-message'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {AddressSetDefault} from "../actions/profile";
import {FetchUserDetails} from "../actions/auth";
import {ApiUrl} from '../AppConstants';

const useStyles = makeStyles((theme) => ({


  avatarDiv: {
    [theme.breakpoints.down('xs')]: {
      backgroundColor: blue[100],
      width: '100vw',
      height: theme.spacing(14),
    },
    height: theme.spacing(18),


  },
  userDetailsDiv: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3),
    },
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(3)

  },
  AddressDiv: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    padding: theme.spacing(3),
  },
  AddressCardDiv: {

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    padding: theme.spacing(3),
    '&:hover': {
      boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
    }

  },
  formPosition: {
    paddingBottom: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      // padding: theme.spacing(9),
    },
  },
  root: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
    //overflow: 'hidden',
    flexDirection: 'column'
  },
  btnStyling: {
    width: "60%",
    padding: "2%",
    textAlign: "center",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)"
  },
  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginLeft: 'auto',
    position: "relative",
    top: "60%",
    transform: "translateY(-50%)",
    [theme.breakpoints.down('xs')]: {
      top: "80%",
      margin: 'auto',
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'red'
  },
  EditIconMargin: {
    marginLeft: theme.spacing(3),
    cursor: 'pointer'
  },
  AddressButton: {
    marginLeft: theme.spacing(3),
  },

  container: {
    [theme.breakpoints.down('md')]: {
      padding: '0px'
    },
  },
  EditIcon: {
    cursor: 'pointer'
  },
  DeleteButton: {
    marginTop: theme.spacing(2)
  },
  cardShadow: {
    '&:hover': {
      boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
    },
    cursor: 'pointer'
  }

}));

export const UserProfile = (props) => {
  const classes = useStyles();
  console.log(props, 'user profile props');  

  const { history } = props;
  const { userStore, setUserStore } = useStore();
  const [showAddAddressDialog, setShowAddAddressDialog] = useState(false)
  const [showSpinner, setShowSpinner] = useState(true)
  const [showUserDetailsEditDialog, setShowUserDetailsEditDialog] = useState(false)
  const [showAddressEditDialog, setShowAddressEditDialog] = useState(false)
  const [showUserDetailsFlash, setShowUserDetailsFlash] = useState(false)
  const [showAddressFlash, setShowAddressFlash] = useState(false)
  const [AddressToEdit, setAddressToEdit] = useState({ FirstAddress: "", StreetDetails: "", City: "", Phone: "", pincode: "" })
  const { setTokenStore } = useStore();
  const { addressStore, setAddressStore } = useStore()
  const [showAddressDeletedFlash, setShowAddressDeletedFlash] = useState(false)

  useEffect(() => {
    if (!history.location.state) {
      setShowSpinner(false)
    }
    // fetchUserDetails()
    // dialog has a side-effect if this not checked
    //document.body.style.overflow = 'auto';
  },[history.location.state])
  
  const handleChange = (addressId, event) => {
    //setState({ ...state, [event.target.name]: event.target.checked });
    if(event.target.checked) {
      SetDefaultAddress(addressId);
    }
  };

  const SetDefaultAddress = (addressId) => {   
    AddressSetDefault(addressId, userStore.user.UserId)
    .then(response => {fetchUserDetails();console.log('SetDefaultAddress Success');})
        .catch(error => {
            //this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);
        });
}


  const fetchUserDetails = (UpdateType) => {
    FetchUserDetails()
      .then(result => {
        console.log(result, 'fetch in login') 
              var body = result.data;
              var user = body.data[0]
              var address = body.address
              console.log('address from backend', address)

              window.localStorage.setItem('token', body.token)
              window.localStorage.setItem('phone', user.Phone)

              setAddressStore({ address, type: 'Address' })
              setUserStore({ user, type: 'User' })

              setTokenStore({ token: user.token })
      })
      .catch(error => {
        console.log("error from server", JSON.stringify(error, Object.getOwnPropertyNames(error)));
      });
  }

  console.log(userStore.user === null, 'user obj length equals')
  console.log(userStore.user, 'user obj')
  console.log(userStore, 'user store')
  console.log(userStore.user.length, 'user obj length')


  const handleAddAddress = () => {

    setShowAddAddressDialog(true)
  }

  const handleAddAddressDialogClose = () => {
    setShowAddAddressDialog(false)
  }

  const closeModal = () => {
    setShowAddAddressDialog(false)
  }

  const handleUserDetailsEdit = () => {
    setShowUserDetailsEditDialog(true)
  }

  const handleAddressEdit = (Address) => {
    console.log(Address, 'address to edit')
    if(Address.AddressNickName !== 'Shop Pickup') {
    setAddressToEdit(Address)
    setShowAddressEditDialog(true)
    }
  }

  const handleEditUserDialogClose = () => {
    setShowUserDetailsEditDialog(false)
  }

  const handleEditAddressDialogClose = () => {
    setShowAddressEditDialog(false);
    document.body.style.overflow = 'auto';
  }

  const handleUserDetailsUpdated = () => {
    fetchUserDetails('User')
    setShowUserDetailsFlash(true)
    setShowUserDetailsEditDialog(false)
  }
  const handleAddressUpdated = () => {
    fetchUserDetails('Address')
    setShowAddressEditDialog(false)
    setShowAddAddressDialog(false)
    setShowAddressFlash(true)
  }
  const deleteAddress = (addressId) => {
    const payload = {
      AddressId: addressId
    }
    console.log(payload, 'payload in delete address')
    fetch(ApiUrl + '/profile/AddressDelete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': window.localStorage.token
      },
      body: JSON.stringify(payload)
    })
      .then(result => {
        if (result.status === 200)
          result.json().then(body => {
            if (body.success === true) {
              fetchUserDetails()
              setShowAddressDeletedFlash(true)
            }
            else {
              //TODO: handle some error occured
            }
          })
      })
  }


  return (

    <div >
      <div className={classes.root}>
        {/* <Header history={props.history} /> */}
        {showUserDetailsFlash ?
          <div >
            <FlashMessage duration={5000} >
              <div className='flashStyling text-center'>
                Profile Updated
                        </div>
            </FlashMessage>
          </div> : ""}

        {showAddressFlash ?
          <div >
            <FlashMessage duration={5000} >
              <div className='flashStyling text-center'>
                Address Updated
                        </div>
            </FlashMessage>
          </div> : ""}

        {showAddressDeletedFlash ?
          <div >
            <FlashMessage duration={5000} >
              <div className='flashStyling text-center'>
                Address deleted
                      </div>
            </FlashMessage>
          </div> : ""
        }


        {/* <Hidden smDown>
          <MenuPane history={history} />
        </Hidden> */}
        <Container maxWidth="lg" className={classes.container}>

          {(Object.keys(userStore.user).length !== 0) ? (
            <div className={classes.formPosition}>
              <Card>
                <Grid container>
                  <Grid item xs={12} md={6} lg={6}>
                    <div className={classes.avatarDiv}>
                      <Avatar className={classes.blue}
                        src={NoProfilePic} />
                      {/* <PersonOutlineOutlinedIcon style={{ fontSize: '400%' }} /> */}
                      {/* </Avatar> */}
                    </div>

                  </Grid>
                  <Grid className={classes.userDetailsDiv} item xs={12} md={5} lg={5}>
                    <Typography variant="h5"> {userStore.user.FirstName} {userStore.user.LastName}
                      <span className={classes.EditIconMargin}><EditTwoToneIcon className={classes.EditIcon} onClick={() => handleUserDetailsEdit()} /></span>
                    </Typography>

                    <Typography variant="h6"><PhoneIphoneOutlinedIcon style={{ color: "blue" }} />   {userStore.user.Phone}</Typography>
                    <Typography variant="h6"><EmailOutlinedIcon style={{ color: "blue" }} />   {userStore.user.Email}</Typography>
                  </Grid>
                </Grid>
                {/* {isFetched? user.address.map((item)=><p>{item.AddressLine1}</p>):""} */}
                <div className={classes.AddressButton}>
                  <Button variant="contained" color="primary" className={classes.AddressButton} onClick={handleAddAddress}>Add Address</Button> <br />
                </div>
                <Grid container spacing={2}>


                  {(addressStore.address).map((add , index) =>
                    
                    <Grid className={classes.AddressDiv} item xs={12} sm={4}>
                      <div className={classes.AddressCardDiv} >
                        <Card variant="outlined">
                          <CardContent>
                            {/* <Typography> Address : {index+1} <br/> {id.addressLine1}, {id.addressLine2}<br />{`${toFirstCharUppercase(id.city)}`}<br /> Pin: {id.pinCode}</Typography> */}
                            <Typography><span style={{ fontWeight: 'bold' }}> {add.AddressNickName} </span>
                              <br />{add.FirstAddress}
                              <br />{add.StreetDetails}
                              <br />{add.City} - {add.pincode}
                              <br />GSTIN NO: {add.GST}<br />
                              <br />{add.ContactPerson}
                              <br />Phone: {add.Phone?add.Phone[0]:''}                            
                            </Typography>
                            {/* <Button variant="contained" onClick={setDefaultAddress}>Set as default</Button> */}
                          </CardContent>
                          <CardActions>
                            {((add.UserId.length > 1 && add.UserId[0] !== 0) )?
                            <span>
                              <Tooltip title="Edit"><EditTwoToneIcon className={classes.EditIcon} onClick={() => handleAddressEdit(add)} /></Tooltip>
                              <Tooltip title="Delete"><DeleteForeverTwoToneIcon className={classes.EditIcon} onClick={() => deleteAddress(add.AddressId)} /></Tooltip>
                              <Tooltip title="Set as Default"><FormControlLabel control={<Switch checked={add.IsDefault} onChange={(e) => handleChange(add.AddressId, e)} name="checkedB" color="primary"/>}label="Default"/></Tooltip>
                            </span>
                            :
                            <Tooltip title="Set as Default"><FormControlLabel disabled="true" control={<Switch checked={add.IsDefault} name="checkedBC" color="primary"/>}label="Default"/></Tooltip>
                            }
                          </CardActions>
                        </Card>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Card>
              <Dialog open={showAddAddressDialog}>
                <DialogTitle className={classes.root}>
                  <IconButton className={classes.closeButton} aria-label="close" onClick={handleAddAddressDialogClose}>
                    <CloseIcon />
                  </IconButton>
                Add Address
              </DialogTitle>
                <DialogContent>
                  <EditAddress modelOpen={closeModal} AddressUpdated={handleAddressUpdated} gst={""} />
                </DialogContent>


              </Dialog></div>)
            : <Redirect to="/AddUserProfile" />}

          <Hidden mdUp >
            <Footer history={history} />
          </Hidden>
          <Spinner showSpinner={showSpinner} />
        </Container>
      </div>

      <Dialog open={showUserDetailsEditDialog}>
        <DialogTitle className={classes.root}>
          <IconButton className={classes.closeButton} aria-label="close" onClick={handleEditUserDialogClose}>
            <CloseIcon />
          </IconButton>
          Edit Account Details
        </DialogTitle>
        <DialogContent>
          <EditUserDetails userId={userStore.user.UserId} firstName={userStore.user.FirstName} lastName={userStore.user.LastName} email={userStore.user.Email}
            phoneNumber={userStore.user.Phone} branchId={userStore.user.branchid} ProfileUpdated={handleUserDetailsUpdated} />
        </DialogContent>
      </Dialog>

      <Dialog open={showAddressEditDialog}>
        <DialogTitle className={classes.root}>
          <IconButton className={classes.closeButton} aria-label="close" onClick={handleEditAddressDialogClose}>
            <CloseIcon />
          </IconButton>
                Edit Address
              </DialogTitle>
        <EditAddress addressName={AddressToEdit.AddressNickName} addressLine1={AddressToEdit.FirstAddress} addressLine2={AddressToEdit.StreetDetails} phone={AddressToEdit.Phone?AddressToEdit.Phone[0]:''}
          city={AddressToEdit.City} pinCode={AddressToEdit.pincode} AddressUpdated={handleAddressUpdated} addressId={AddressToEdit.AddressId}
          contactPerson={AddressToEdit.ContactPerson} gst={AddressToEdit.GST} IsDefault={AddressToEdit.IsDefault}/>
      </Dialog>


    </div>

  )
}

